import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import breakpoints from 'lib/breakpoints'
import MediaQuery from 'react-responsive'
import ReactResizeDetector from 'react-resize-detector'
import ClassNames from 'classnames'
import BEM from 'lib/BEM'

import { setHeaderHeight } from 'reduxStore/actions'

import Container from 'components/layout/Container'
import Logo from 'components/global/Logo'
import MainNav from 'components/global/MainNav'
import Hamburger from 'components/global/Hamburger'

export class Masthead extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 126
        }

        this._headerElement = React.createRef();
        this.bem = new BEM('masthead');
    }

    _handleResize() {
        let height = this._headerElement.current.clientHeight;
        this.props.setHeaderHeight(height)
    }

    render() {
        let bem = this.bem;

        return (
            <div className={ClassNames(bem.b, {
                [bem.m('reverse')]: this.props.reverseColors,
                'has-spacer': this.props.spacer,
                'is-at-top': this.props.atTop,
            })}>
                <div
                    className = {bem.e('actual')}
                    ref = {this._headerElement}
                >
                    <Container>
                        <div className={bem.e('logo')}>
                            <Link to="/" className="u-block">
                                <h1 className="u-accessible-hide">Invoke</h1>
                                <Logo />
                            </Link>
                        </div>
                        <div className={bem.e('nav')}>
                            <MainNav />
                        </div>
                        <MediaQuery maxWidth={breakpoints.mid}>
                            <Hamburger
                                className = {bem.e('hamburger')}
                            />
                        </MediaQuery>
                        <ReactResizeDetector
                            handleWidth
                            handleHeight
                            onResize = {(event) => this._handleResize(event)}
                            refreshMode = 'debounce'
                            refreshRate = {100}
                        />
                    </Container>
                </div>
                { this.props.spacer ? (
                    <div
                        className = {bem.e('spacer')}
                    />
                ) : (null) }
            </div>
        )
    }
}

Masthead.defaultProps = {
    spacer: true,
    reverseColors: false
}

const mapStateToProps = (state) => ({
    height: state.presentation.headerHeight,
    spacer: state.presentation.headerSpacer,
    reverseColors: !state.presentation.headerSpacer && state.pageYOffset <= 0,
    atTop: state.pageYOffset <= 0
})

const mapDispatchToProps = (dispatch) => ({
    setHeaderHeight: (height) => {
        dispatch(setHeaderHeight(height))
    }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Masthead));
